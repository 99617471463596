(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/card-puff/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/card-puff/assets/javascripts/render-component.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';
const {
  core
} = isServer && require('trinidad-core') || {};
const bannerHelpers = isServer ? core.components.require('banner_common', 'banner-helpers') : svs.banner_common.banner_helpers;
const {
  getCallToAction,
  getRCTools,
  groupNumbers
} = bannerHelpers;
const layoutType = 'card_puff';

const renderComponent = async (model, widget, position, req, res) => {
  const {
    callToActions,
    images,
    id,
    layoutConfig,
    targetOffer
  } = model;
  const tracking = {
    widget,
    position
  };
  const {
    contentType,
    features,
    hbsCompiled,
    isAdmin,
    trackingString
  } = getRCTools({
    layoutType,
    model,
    res,
    req,
    tracking
  });
  const actionViewConfig = {
    block: true,
    focus: true
  };
  const callToAction = getCallToAction(callToActions[0], actionViewConfig);
  const heading = groupNumbers(layoutConfig.header);
  const content = groupNumbers(layoutConfig.content);
  return hbsCompiled(_objectSpread(_objectSpread({}, layoutConfig), {}, {
    callToAction,
    content,
    contentType,
    features,
    heading,
    id,
    images,
    isAdmin,
    targetOffer,
    trackingString
  }));
};
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  svs.banner_layouts[layoutType] = {
    renderComponent
  };
}

 })(window);